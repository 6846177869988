// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

import wx from 'weixin-js-sdk'
import FaceVerify from '@alipay-inc/mpaas-h5-face-verify'

const dom = document.getElementById('root')
const certifyId = window.location.href.match(/=(\S*)/)[1]
console.log(certifyId)
const face = new FaceVerify({
  dom,
  certifyId,
  callback: (res) => {
    console.log(res, 'verify callback~')
    // 跳转到小程序处理结果页面
    wx.miniProgram.navigateTo({
      url: `/pages-authentication/resultCheck?certifyStatus=${res.code}`
    })
  },
})
face.faceRender()

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
